h1 {
  margin: 0;
}
input {
  outline: none;
}
.main-show {
  background-color: white;
  margin: 20px;
  margin-top: 10px;
}
.order-list {
  box-sizing: border-box;
}
.order-list .table-header {
  padding: 0 20px;
  height: 60px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-list .table-header .table-name {
  font-size: 18px;
  margin: 0;
}
.order-list .table-header .table-operation {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #808080;
}
.order-list .table-header .table-operation .btn-add {
  margin-right: 50px;
}
.order-list .table-header .table-operation .order-num {
  margin-right: 35px;
}
.order-list .table-header .table-operation .order-num .order-num-text {
  margin-right: 5px ;
}
.order-list .table-header .table-operation .order-num .order-num-num {
  width: 150px;
  height: 35px;
  border: 1px solid #EEEEEE;
}
.order-list .table-header .table-operation span {
  margin-right: 5px ;
}
.order-list .table-content {
  padding: 0 20px;
  padding-top: 20px;
}
.modal-confirm {
  height: 340px;
}
.modal-confirm .confirm-text {
  font-size: 14px;
  text-align: center;
  margin-bottom: -400px;
}
.ml50 {
  margin-left: 50px;
}
.link {
  color: #2db7f5;
}
