.qa-add-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.qa-add-box input,
.qa-add-box textarea {
  width: 600px;
  height: 40px;
  outline: none   ;
  background: #F9F9F9;
  border: 1px solid #EEEEEE;
}
.qa-add-box textarea {
  height: 100px;
  resize: none;
}
.qa-add-box .qa-add-item-title {
  width: 65px;
  font-size: 14px;
  margin: 0 5px 0 0;
  white-space: nowrap;
}
