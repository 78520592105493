.lr{
    float: right;
}
.lf{
    float: left;
}
.wuliiu-tuihuo{
    width: 1680px;
    height: 920px;
    background-color: #fff;
    margin: auto;
    .tuihuoTop{
        width: 1680px;
        height: 60px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        padding: 0 20px;
        .tuihuo-lf{
            line-height: 60px;
        }
        .tuihuo-lr{
            line-height: 60px;
           
            .ant-btn {
                width: 90px;
                height: 30px;
                background: #1A69E0;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
    .tuihuoContent{

        height: 795px;
        padding: 21px;
        .tuihuoTable{
            border: 1px solid #EEEEEE;
            border-collapse:collapse;
            width: 100%;
            table-layout:fixed;
            .tuihuoThTr{
                height: 40px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                background: #FFFFFF;
                th{
                    text-align: center;
                }
                th:nth-child(1){
                    // padding-left: 29px;
                }
    
            }
            .tuihuoListTdTr{
                height: 40px;
                border-top: 1px solid #EEEEEE;
                background: #FFFFFF;
                text-align: center;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                td:nth-child(1){
                    // padding-left: 29px;
                }
                td:nth-child(5){
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #D94511;
                }
                td:nth-child(9){
                    .sjbj{
                        margin-right: 10px;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #1A69E0;
                    }
                    .xiangqing{
                        // margin-left: 10px;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #000000;
                    }
                }
            }
            .tuihuoListTdTr:nth-child(odd){
                background: #F9F9F9;
            }
            td:nth-child(8){
                .tuihuoxg{
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #1A69E0;
                    margin-right: 10px;
                }
                .tuihuodel{
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                }
            }
        }
    }
    

}
.tuihuoModal{
    .ant-modal-content{
        width: 800px;
        height: 520px;
        background: #FFFFFF;
        .ant-modal-body{
            padding-left:18px;
            padding-top: 20px;
            
            .tuihuoItem{
                height: 40px;
                line-height: 40px;
                margin-bottom: 10px;
            .tuihuoSpan{
                float: left;
                width: 95px;
            }
            .tuihuoInput{
                width: 600px;
                height: 40px;
                background: #F9F9F9;
                border: 1px solid #EEEEEE;
                padding-left: 19px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #C7C7C7;
            }
                
                .diqu{
                    .diquSpace{
                        .ant-space-item{
                            .xialadiqu{
                                float: left;
                                width: 600px;
                                height: 40px;
                                background: #F9F9F9;
                                border: 1px solid #EEEEEE;
                                :nth-child(1){
                                    float: left;
                                }
                                :nth-child(2){
                                    float: right;
                                    line-height: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
    .ant-modal-footer{
        text-align: center;
        border-top: none;
        .ant-btn:nth-child(1){
            margin-right: 30px;
            width: 100px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #1A69E0;
        }
        .ant-btn:nth-child(2){
            width: 100px;
            height: 40px;
            background: #1A69E0;
        }
    }
    .modalFooter{
        .ant-btn:nth-child(2){
            margin: 0 30px;
        }
    }

    
}
.tuihuoDelModal{
    .ant-modal-content{
        width: 800px;
        height: 340px;
        background: #FFFFFF;
        p{
            text-align: center;
            margin-top: 71px;
        }
        .ant-modal-header{
            text-align: center;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
        }
    }
    .ant-modal-footer{
        text-align: center;
        border-top: none;
        margin-top: 20px;
        .ant-btn:nth-child(1){
            margin-right: 30px;
            width: 100px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #1A69E0;
        }
        .ant-btn:nth-child(2){
            width: 100px;
            height: 40px;
            background: #1A69E0;
        }
    }
    .modalFooter{
        .ant-btn:nth-child(2){
            margin: 0 30px;
        }
    }
}

