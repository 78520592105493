.home {
  width: 100%;
  height: 100vh;
  display: flex;
  align-content: stretch;
}
.home .left-menu {
  width: 200px;
  min-width: 200px;
  height: 100%;
  overflow: hidden;
  background-color: #1F2128;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.home .left-menu .logo {
  min-width: 200px;
  min-height: 100px;
  width: 200px;
  height: 100px;
  background-color: #1A69E0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home .left-menu .logo .logo-img {
  width: 69px;
  height: 61px;
}
.home .left-menu .menu-nav::-webkit-scrollbar {
  display: none;
}
.home .left-menu .menu-nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  overflow: scroll;
}
.home .main {
  flex-shrink: 1;
  flex-grow: 2;
  background-color: #f0f2f5;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home .main .top {
  width: 100%;
  height: 70px;
  background-color: #fff;
  flex-shrink: 0;
}
.home .main .top .ant-row {
  height: 100%;
}
.home .main .top .ant-row .ant-col-12 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home .main .top .ant-row .ant-col-12 .userheader-middle {
  height: 30px;
  line-height: 30px;
  width: 500px;
  color: #fff;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 0 20px;
  background-color: #919CAD;
}
.home .main .top .ant-row .ant-col-6 {
  display: flex;
  align-items: center;
  padding-left: 30px;
}
.home .main .top .ant-row .ant-col-6 .header {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}
.home .main .top .ant-row .ant-col-6 .header .header-img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.home .main .top .ant-row .ant-col-6 .user-name {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}
.home .main .top .ant-row .ant-col-6 .user-login-time {
  color: #808080;
  font-size: 12px;
}
.home .main .top .ant-row .top-right {
  display: flex;
  padding-right: 50px;
  flex-direction: row-reverse;
}
.home .main .top .ant-row .top-right .userheader-tools {
  width: 200px;
  display: flex;
  justify-content: space-between;
}
.home .main .top .ant-row .top-right .userheader-tools .ant-btn-circle {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home .main .record {
  flex-shrink: 0;
  width: 100%;
  height: 50px;
  background-image: linear-gradient(to bottom, #f0f2f5, #fff 20%);
}
.home .main .content::-webkit-scrollbar {
  display: none;
}
.home .main .content {
  overflow-y: scroll;
  flex-grow: 2;
  padding-top: 10px;
}
