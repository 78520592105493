.commodityList{
    width: 1680px;
    height: 916px;
    margin: auto;
    background: #ffff;
     .commodityListTop{
        width: 1680px;
        height: 60px;
        padding-left: 30px;
        padding-right: 20px;
        background: #FFFFFF;
        border-bottom: 1px solid #EEEEEE;
        .typeSearch{
            width: 300px;
            height: 60px;
            line-height: 60px;
            float: left;
            span{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #808080;
            }
            input{
                width: 200px;
                height: 35px;
                padding-left: 10px;
                margin-left: 10px;
                background: #FFFFFF;
                border: 1px solid #EEEEEE;
            }
        }
    }
    .ant-space{
        height: 60px;
        line-height: 60px;
        margin-left: 29px;
        .ant-space-item .ant-btn{
            width: 100px;
            line-height: .36;
            height: 36px;
        }
    } 


}



.fenleiModal{
    .ant-modal-content{
        width: 800px;
        height: 600px;
        background: #FFFFFF;
        .ant-modal-body{
            padding-top:0;
            padding-bottom: 0;
        }
        .ant-tabs-nav-list .ant-tabs-tab{
            height: 59px;

        }
        .ant-space .ant-space-item .addxiala{
            width: 600px;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 20px;

        }
        .ant-space .ant-space-item .addxiala>span{
            float: left;
        }
        .ant-space .ant-space-item .addxiala .anticon{
            float: right;
        }
        .ant-tabs-tabpane .addguige{
            width: 80px;
            height: 30px;
            margin-bottom: 20px;
            background: #1A69E0;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
        }
        .ant-tabs-tabpane .addguige  span{
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
        }

    }
    .ant-modal-content .ant-modal-footer{
        text-align: center;
        border-top: none;
    }
    .ant-modal-content .ant-modal-footer .ant-btn{
        width: 100px;
        height: 40px;
    }
    
}








/* .ant-btn .ant-dropdown-trigger{
    width: 100px;
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
} */
.xiaLazt{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #808080;
}
.xiala{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
}
.commodityListAdd{
    float: right;
    line-height: 60px;
    .ant-btn{
        width: 90px;
        height: 30px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
    }



}


.specificationK{
    width: 500px;
    height: 220px;
    padding-left: 20px;
    padding-right: 33px;
    margin-bottom: 50px;
    background: #F9F9F9;
    border: 1px solid #EEEEEE;
}
.specificationK .specificationKTop{
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 17px;
    height: 47px;
    line-height: 47px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
}

.specificationKList{
    display: flex;
    margin-bottom: 10px;
}
.specificationKItem{
    width: 150px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    padding-left:21px ;
}
.specificationKItem:nth-child(2){
    margin-left: 20px;
}
.specificationKItem input{
    width: 100%;
    height: 100%;
    border: none;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
}

.specificationKcz{
    width: 150px;
    height: 30px;
}
.caozuo{
    /* display: flex; */
    float: right;
}
.czfu{
    float: left;
    margin-left: 15px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
}
.specificationKFooter{
    display: flex;
    justify-content: center;
}
.specificationKFooter .ant-btn:nth-child(1){
    margin-right: 30px;
}
.specificationKFooter{
    margin-top: 130px;
}
.addGuiGeModal{
    .ant-modal-content{
        width: 600px;
        height: 500px;
        background: #FFFFFF;
    }
    .ant-modal-footer{
        text-align: center;
        border-top: none;
        margin-top: 150px;
        .ant-btn:nth-child(1){
            width: 200px;
            height: 40px;
            background: #F9F9F9;
            border-radius: 2px;
        }
        .ant-btn:nth-child(2){
            width: 200px;
            height: 40px;
            background: #1A69E0;
            border-radius: 2px;
        }
    }
  
}
.addguigeK{
    width: 550px;
    height: 90px;
    padding-left: 22px;
    padding-right: 50px;
    margin-bottom: 20px;
    background: #F9F9F9;
    border: 1px solid #EEEEEE;
}
.addCanShuModal{
    .ant-modal-content{
        width: 600px;
        height: 500px;
        background: #FFFFFF;
    }
    .ant-modal-footer{
        text-align: center;
        border-top: none;
        margin-top: 150px;
        .ant-btn:nth-child(1){
            width: 200px;
            height: 40px;
            background: #F9F9F9;
            border-radius: 2px;
        }
        .ant-btn:nth-child(2){
            width: 200px;
            height: 40px;
            background: #1A69E0;
            border-radius: 2px;
        }
    }
}
.commodityListBody{
    padding: 20px;
    .listTable{
        width: 100%;
        .listThead{
            width: 100%;
            height: 40px;
            background: #FFFFFF;
            .listTr{
                height: 40px;
                line-height: 40px;
                display: flex;
                justify-content: space-between;
                border-top: 1px solid #EEEEEE;
                border-left: 1px solid #EEEEEE;
                border-right: 1px solid #EEEEEE;
                th{
                    // flex: 1;
                    width: 100px;
                    text-align: center;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                    // border:1px solid #ccc;
                }
                th:nth-child(2){
                    width: 200px;    
                }
                th:nth-child(4){
                    width: 200px;    
                }
            }
        }
        .listTbody{
            .TbodyTr{
                width: 1640px;
                height: 50px;
                line-height: 50px;
                display: flex;
                justify-content: space-between;
                border-top: 1px solid #EEEEEE;
                border-left: 1px solid #EEEEEE;
                border-right: 1px solid #EEEEEE;
                td{
                    // flex: 1;
                    // width: 100px;    
                    text-align: center;
                    // border:1px solid #ccc;
                    
                }
                td:nth-child(1){
                    // min-width: 190px;
                    width: 100px;    
                }
                td:nth-child(2){
                    // min-width: 190px;
                    width: 200px;    
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                td:nth-child(3){
                    // min-width: 190px;
                    width: 100px;
                    text-align: center;
                }
                td:nth-child(4){
                    width: 200px;
                    overflow: hidden;
                }
                td:nth-child(5)>div{
                    width: 80px;
                    margin-right: 10px;
                    display: flex;
                    justify-content: space-between;
                }
                :nth-child(5)>div span:nth-child(1){
                    font-size: 12px;
                    cursor: pointer;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #1A69E0;
                }
                :nth-child(5)>div span:nth-child(2){
                    font-size: 12px;
                    cursor: pointer;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                }
              
            }
            .TbodyTr:nth-child(odd){
                background: #F9F9F9;
            }
              tr:nth-last-child(1) {
                    border-bottom:1px solid #EEEEEE;
                }
        }
    }
}
.commodityListFooter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    .listFooterInfo{
        float: left;
        min-width: 300px;
        height: 60px;
        line-height: 60px;
        margin-left: 72px;
        span{
            margin-right: 29px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            &:nth-child(2){
                margin-left: 13px;
            }
        
        }
        button{
            width: 50px;
            height: 30px;
            background: #FFFFFF;
            border: 1px solid #1A69E0;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #1A69E0;
            padding: 0;
            line-height: 30px;
            display: table-cell;
            vertical-align: middle;
        }
    }
    .listFooterFenye{
        float: right;
        margin-right: 20px;
    }
}
