.login-form {
  position: absolute;
  left: 53%;
}
.login-form .tittle {
  color: #fff;
  font-size: 40px;
  text-align: center;
  width: 100%;
}
.login-form .login-form-main {
  background-color: white;
  width: 550px;
  height: 550px;
  box-sizing: border-box;
  border: 1px solid #fff;
  padding: 20px 40px 40px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.login-form .login-form-main .login-title {
  font-size: 28px;
  text-align: center;
}
.login-form .login-form-main .form {
  position: relative;
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-form .login-form-main .form .mobile {
  border-bottom: 1px solid #E5E5E5;
  width: 100%;
  height: 70px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.login-form .login-form-main .form .mobile input {
  border: none;
  outline: none;
  width: 250px;
  height: 30px;
  font-size: 20px;
  margin-left: 10px;
}
.login-form .login-form-main .form .mobile .getcode {
  position: absolute;
  right: 0;
}
.login-form .login-form-main .form .mobile img {
  width: 25px;
  height: 25px;
}
.login-form .login-form-main .switch-method {
  width: 100%;
  height: 80px;
  font-size: 16px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.login-form .login-form-main .submit {
  width: 300px;
  height: 45px;
  font-size: 20px;
  background-color: #1a69e0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input {
  -moz-appearance: textfield;
}
