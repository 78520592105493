.loading {
  width: 100%;
  height: 100%;
  background-color: white;
  margin: 0 20px;
  box-sizing: border-box;
  width: 1680px;
  height: 790px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-center {
  margin-top: -130px;
  width: 100px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 1.5rem;
  height: 1.5rem;
}
.ant-spin-dot-item {
  width: 100px !important;
  height: 100px !important;
}
