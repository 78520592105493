@import '~antd/dist/antd.css';
.app {
  /* text-align: center; */
  width: 100%;
  height: 100%;
  /* min-height: 1080px; */
}
#root{
  width: 100%;
  height: 100%;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*{
  padding: 0 ;
  margin: 0;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.pointer{
  cursor: pointer;
}
.ml10{
  margin-left: 10px;
}
.color-black{
  color: #000 !important;
}
.f-w6{
  font-weight: 600;

}