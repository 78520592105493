.detail-header {
  display: flex;
  align-items: center;
  padding: 20px;
  height: 60px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
}
.detail-header .img-close {
  width: 15px;
  height: 14px;
  margin-right: 20px;
}
.detail-header .detail-title {
  font-size: 18px;
  margin: 0;
}
.detail-main {
  padding: 20px 40px;
}
.detail-main .order-status {
  box-sizing: border-box;
  width: 100%;
  height: 130px;
  background-color: #fdf6f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
}
.detail-main .order-status .order-status-now {
  display: flex;
  align-items: center;
  height: 40px;
}
.detail-main .order-status .order-status-now .img-warning {
  width: 40px;
  height: 40px;
}
.detail-main .order-status .order-status-now .order-status-text {
  margin-left: 20px;
  font-size: 24px;
  font-family: MicrosoftYaHei;
  font-weight: bold;
  color: #000000;
}
.detail-main .order-status .order-status-endtime {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #808080;
  display: flex;
  width: 243px;
  height: 16px;
  justify-content: space-between;
}
.detail-main .scard {
  margin-top: 20px;
  border: 1px solid #eee;
}
.detail-main .scard .scard-title {
  padding: 13px 0 11px 20px;
  background-color: #F9F9F9;
  font-weight: 600;
}
.detail-main .scard .scard-content {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #eee;
}
.detail-main .scard .scard-content .gray {
  color: #808080;
}
.detail-main .scard .content-left {
  display: flex;
  padding: 20px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #000000;
}
.detail-main .scard .content-lleft {
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: space-between;
}
.detail-main .scard .content-right {
  border-left: 1px solid #eee;
  box-sizing: border-box;
  padding: 42px 0;
  display: flex;
  flex-direction: column;
  width: 120px;
  text-align: center;
  color: #DB4F1E;
  font-size: 14px;
}
.detail-main .scard .content-right .total {
  color: #000;
}
.detail-main .scard .content-right .price {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}
.detail-main .scard .scard-foot {
  padding: 20px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #000000;
  border-top: solid 1px #eee;
  display: flex;
  justify-content: space-between;
}
.detail-main .scard .scard-foot .modify {
  width: 60px;
  height: 30px;
  color: #D94511;
  border: 1px solid #D94511;
  text-align: center;
  margin: 0;
  line-height: 30px;
  font-size: 14px;
  cursor: pointer;
}
.detail-main .scard .scard-foot .left-span {
  margin-right: 70px;
}
.detail-main .scard .scard-foot .orange {
  color: #D94511;
  margin-left: 10px;
}
.detail-main .scard .logistics-info {
  padding: 35px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.detail-main .scard .logistics-info .logistics-add {
  color: #D94511;
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  border: solid 1px #D94511;
  cursor: pointer;
}
.shipping-info-add {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
}
.shipping-info-add .div {
  margin-top: 20px;
  height: 40px;
  text-align: left;
  line-height: 40px;
}
.shipping-info-add .div .span {
  width: 61px;
  height: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.shipping-info-add .div .input {
  width: 600px;
  height: 40px;
  background: #F9F9F9;
  outline: none;
  border: 1px solid #eee;
}
.ant-modal-footer {
  margin-top: 230px;
}
.ant-select-selector {
  width: 600px;
  height: 40px;
  background: #F9F9F9 !important;
  outline: none !important;
  border: 1px solid #eee !important;
}
