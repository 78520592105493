.pd20 {
  padding: 20px ;
}
.pd10-20 {
  padding: 10px 20px;
}
.goodsinfo {
  font-size: 14px;
  display: flex;
}
.goodsinfo .goodsinfo-title {
  font-size: 14px;
  margin-right: 10px;
  line-height: 40px;
  width: 100px;
  text-align: justify;
}
.goodsinfo .goodsinfo-text {
  font-size: 14px;
  text-indent: 24px;
  background: #F9F9F9;
  padding: 0 10px;
  width: 50%;
  line-height: 40px;
  border: 1px solid #EEEEEE;
  outline: none;
  box-sizing: border-box;
}
.goodsinfo .goodsinfo-imgs-area {
  display: flex;
}
.goodsinfo .goodsinfo-imgs-area .goodsinfo-imgs {
  margin-right: 20px;
}
.goodsinfo .goodsinfo-detail-text {
  padding-left: 10px;
  height: 150px;
  resize: none;
}
.df-jcc {
  width: 100%;
  padding: 20px 350px;
  border-top: 1px solid #eee;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.df-jcc .w100 {
  width: 100px;
}
