.main-show .detail-main .dflex {
  flex-direction: row;
}
.main-show .detail-main .dflex .detail-item:nth-last-child(1) {
  border-right: none;
}
.main-show .detail-main .dflex .detail-item:nth-child(2) .detail-item-value {
  color: #D94511;
}
.main-show .detail-main .dflex .detail-item {
  height: 60px;
  flex-grow: 1;
  border-right: 1px solid #DCDCDC;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-show .detail-main .dflex .detail-item p {
  margin: 0;
  text-align: center;
}
.main-show .detail-main .dflex .detail-item .detail-item-titl {
  font-weight: 400;
  color: #505050;
  font-size: 14px;
}
.main-show .detail-main .dflex .detail-item .detail-item-value {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
.main-show .detail-main .head-img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.main-show .detail-main .content-lleft {
  font-size: 12px;
}
.main-show .detail-main .border-none {
  border: none;
}
