.gerendianpu {
    margin: auto;
    width: 1680px;
    height: 920px;
    background: #fff;

    .gerendianpuTop {
        width: 1680px;
        height: 60px;
        padding-left: 30px;
        padding-right: 20px;
        background: #FFFFFF;
        border-bottom: 1px solid #EEEEEE;

        .gerendianpuTop-lf {
            span {
                line-height: 60px;
                font-size: 18px;
                font-family: MicrosoftYaHei;
                font-weight: 400;
                color: #000000;
            }
        }
    }

    .gerendianpuContent {
        overflow: hidden;
        margin-top: 20px;

        .gerendianpu-item {
            margin-bottom: 10px;
            margin-left: 20px;

            .gerendianpu-upload {
                .ant-upload-picture-card-wrapper {
                    text-align: left;

                    .ant-upload-list {
                        .ant-upload-select {
                            width: 120px;
                            height: 120px;

                            span {
                                text-align: center;
                            }
                        }
                    }
                }

            }

            .upload-fr {
                margin-left: 10px;

                .upload-p {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #909090;

                    span {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FF0000;
                    }
                }
            }

            span {
                margin-right: 10px;
                text-align: right;
                font-size: 14px;
                font-family: Adobe Heiti Std;
                font-weight: normal;
                color: #000000;
            }

            input {
                width: 800px;
                height: 40px;
                background: #F9F9F9;
                border: 1px solid #EEEEEE;
                padding-left: 20px;
            }
        }

        .shenfenzheng {
            margin-top: 20px;
            margin-bottom: 20px;

            img {
                width: 204px;
                height: 126px;
                margin-right: 15px;
            }
        }

    }

    .gerendianpuBottom {
        margin-top: 40px;

        .ant-btn {
            margin-left: 226px;
            width: 90px;
            height: 30px;
            background: #1A69E0;
            font-size: 12px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: #FFFFFF;
        }
    }

    .ant-upload-list-picture-card-container {
        width: 120px;
        height: 120px;
    }

}