.form {
  padding: 40px;
}
.form .form-item .form-item-top {
  display: flex;
}
.form .form-item .form-item-top .form-item-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  padding-right: 15px;
  width: 115px;
  text-align: end;
}
.form .form-item .form-item-top .form-item-input {
  padding-left: 10px;
  width: 600px;
  box-sizing: border-box;
  height: 40px;
  background-color: #F9F9F9;
  border: 1px solid #EEEEEE;
}
.form .form-item .form-item-top .code-num {
  width: 180px;
  margin-right: 20px;
}
.form .form-item .form-item-top .get-code {
  width: 135px;
  height: 40px;
  text-align: center;
}
.form .form-item .form-item-tips {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding-left: 120px;
  color: red;
}
.passwordModify {
  width: 135px;
  height: 40px;
  margin: 100px ;
}
