.detail-header{
    display: flex;
    justify-content: center;

}
.fs14px{
    font-size: 14px;
    margin-right: 15px;
}
.modal-content{
    /* padding: 20px 0; */
}
.detail-area{
    width: 100%;
    height: 350px;
}
.detail-area::-webkit-scrollbar{
    display: none;
    
}
.detail-area{
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width   : none;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    color: #666;
}
.w70px{
    width: 70px !important;
    height: 20px;
}
.flex{
    display: flex;
}
.m020{
    margin: 0 20px;
}
.shipping-info-add{
    padding-top: -20px !important;
}
.shipping-info-add .div{
    margin-top: 0;
}
.desabled:hover{
    cursor:not-allowed;
}
