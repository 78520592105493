 .fl {
     float: left;
 }

 .wuliu {
     // width: 1680px;
     flex-grow: 1;
     margin   : auto;

     .wuliuTop {
         // width: 1680px;
         flex-grow : 1;
         height    : 315px;
         background: #FFF;

         // margin:auto;
         .wuliu-tabs {

             .ant-tabs-nav-list {

                 .ant-tabs-tab {
                     display    : inline-block;
                     width      : 150px;
                     height     : 60px;
                     text-align : center;
                     font-size  : 18px;
                     font-family: Microsoft YaHei;
                     font-weight: 400;
                     color      : #808080;
                 }
             }

             .piliang {
                 height     : 255px;
                 text-align : center;

                 // .ant-btn{
                 //     // width: 100px;
                 //     // height: 40px;
                 //     background: #FFFFFF;
                 //     border: 1px solid #1A69E0;
                 //     // font-size: 16px;
                 //     font-family: Microsoft YaHei;
                 //     font-weight: 400;
                 //     color: #1A69E0;
                 //     margin-right: 10px;
                 // }
                 .weixuanze {
                     font-size   : 14px;
                     font-family : Microsoft YaHei;
                     font-weight : 400;
                     color       : #808080;
                     margin-right: 50px;
                 }

                 .xiazaimoban {
                     font-size  : 14px;
                     font-family: Microsoft YaHei;
                     font-weight: 400;
                     color      : #1A69E0;
                 }
             }

             // .dantiao{
             //     display: flex;
             //     flex-direction: column;
             //     align-items: center;
             //     justify-content: center;
             // }



             .dantiao {
                 height: 255px;

                 // line-height: 255px;
                 .div-item {
                     padding        : 5px;
                     display        : flex;
                     justify-content: center;

                     .left-title {
                         width       : 60px;
                         text-align  : right;
                         margin-right: 10px;
                         display     : inline-block;
                         line-height : 32px;
                     }

                     .inputs {
                         width           : 200px;
                         height          : 32px;
                         box-sizing      : border-box;
                         border          : 1px solid rgb(238, 238, 238);
                         // border: none;
                         padding-left    : 10px;
                         border-radius   : 2px;
                         background-color: rgb(249, 249, 249);
                         // color: #1A69E0;
                         // border: 1px solid #1A69E0;


                     }
                 }

                 .dantiao-dingdan {
                     width        : 500px;
                     margin       : auto;
                     margin-bottom: 10px;

                     // text-align: center;
                     span:nth-child(1) {

                         font-size   : 14px;
                         font-family : MicrosoftYaHei;
                         font-weight : 400;
                         color       : #000000;
                         margin-right: 10px;
                         margin-left : 60px;
                     }

                     span:nth-child(3) {
                         font-size  : 14px;
                         font-family: MicrosoftYaHei;
                         font-weight: 400;
                         color      : #808080;

                     }

                     .ant-btn {
                         width       : 196px;
                         height      : 40px;
                         background  : #FFFFFF;
                         border      : 1px solid #1A69E0;
                         margin-right: 24px;

                         span {
                             margin-left: 0;
                         }
                     }

                 }

                 .dantiao-wuliudanhao {
                     width        : 500px;
                     margin       : auto;
                     margin-bottom: 10px;

                     // text-align: center;
                     span:nth-child(1) {
                         font-size   : 14px;
                         font-family : MicrosoftYaHei;
                         font-weight : 400;
                         color       : #000000;
                         margin-right: 10px;
                         margin-left : 60px;
                     }

                     span:nth-child(3) {
                         font-size  : 14px;
                         font-family: MicrosoftYaHei;
                         font-weight: 400;
                         color      : #808080;

                     }

                     .ant-btn {
                         width       : 196px;
                         height      : 40px;
                         background  : #FFFFFF;
                         border      : 1px solid #1A69E0;
                         margin-right: 24px;

                         span {
                             margin-left: 0;
                         }
                     }
                 }
             }

         }
     }

     .wuliuBottom {
         // width: 1680px;
         flex-grow : 1;
         background: #FFF;
         margin-top: 20px;

         // margin:auto;
         .fahuojilu-top {
             height       : 60px;
             background   : #FFFFFF;
             border-bottom: 1px solid #EEEEEE;
             padding-left : 20px;



         }
     }
 }