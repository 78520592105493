.visitors-views {
  display: flex;
  justify-content: space-between;
  background-color: none;
  margin: 20px;
  margin-top: 10px;
}
.visitors-views .item {
  display: flex;
  height: 170px;
  max-width: 405px;
  box-shadow: 0px 4px 15px 0px rgba(144, 144, 144, 0.1);
  margin-left: 10px;
  flex-grow: 1;
}
.visitors-views .item:nth-child(1) {
  margin-left: 0;
}
.visitors-views .item .blue {
  background-color: #1A69E0;
}
.visitors-views .item .red {
  background-color: #F63D5B;
}
.visitors-views .item .green {
  background-color: #06C59D;
}
.visitors-views .item .orange {
  background-color: #F3862B;
}
.visitors-views .item .item-left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.visitors-views .item .item-left img {
  max-width: 50px;
  max-height: 50px;
}
.visitors-views .item .item-right {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding-left: 25px;
}
.visitors-views .item .item-right .item-title {
  font-size: 18px;
  margin-bottom: 28px;
}
.visitors-views .item .item-right .item-num {
  font-size: 28px;
  font-weight: bold;
}
.br5 {
  border-radius: 5px ;
  border: 1px solid #eee;
}
.charts {
  margin: 30px 40px;
  display: flex;
}
.charts .chart-item {
  height: 170px;
  max-width: 350px;
  flex-grow: 1;
  margin-right: 30px;
  box-shadow: 0px 4px 15px 0px rgba(140, 140, 140, 0.2);
  border-radius: 5px;
}
.charts .chart-item .item-top {
  height: 117px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
}
.charts .chart-item .item-top .num-area {
  display: flex;
  flex-direction: column;
}
.charts .chart-item .item-top .num-area .item-title {
  font-size: 14px;
  font-weight: 400;
}
.charts .chart-item .item-top .num-area .item-num {
  font-size: 24px;
  font-weight: 400;
  color: #010101;
}
.charts .chart-item .item-top .num-area .item-num .num-nuit {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  margin-left: 9px;
}
.charts .chart-item .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.charts .chart-item .dot-blue {
  background-color: #1A69E0;
}
.charts .chart-item .dot-green {
  background-color: #77dfca;
}
.charts .chart-item .dot-orange {
  background-color: #e5815d;
}
.charts .chart-item .item-bottom {
  height: 53px;
  width: 100%;
}
.charts .chart-item .item-bottom .l1 {
  width: 0;
  height: 0   ;
  border-left: 70px solid red;
  border-bottom: 70px solid red;
}
.data-statistical {
  display: flex;
  border: 1px solid #EEEEEE;
  margin: 0 40px 40px;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 40px 30px;
}
.data-statistical .data-item {
  height: 120px;
  background: #F9F9F9;
  border: 1px solid #EEEEEE;
  padding: 20px;
  width: 220px;
  font-weight: 400;
  margin: 0 0 30px 20px;
}
.data-statistical .data-item .data-item-title {
  font-size: 14px;
}
.data-statistical .data-item .data-item-num {
  font-size: 20px;
}
p {
  margin: 0;
}
