.shopCangKu{
    width: 1680px;
    height: 916px;
    margin: auto;
    background: #ffff;
    .shopCangKuTop{
        width: 1680px;
        height: 60px;
        padding-left: 30px;
        padding-right: 20px;
        background: #FFFFFF;
        border-bottom: 1px solid #EEEEEE;
        .shopCangKuTopLf{
            min-width: 300px;
            height: 60px;
            line-height: 60px;
            float: left;
            .xialacd{
                width: 100px;
                height: 35px;
                background: #FFFFFF;
                border: 1px solid #EEEEEE;
            }
            .ant-space:nth-child(2){
                margin-left: 50px;
            }
        }
        .shopCangKuTopRg{
            float: right;
            line-height: 60px;
        }
    }
    .shopCangKuBody{
        padding-left: 29px;
        padding-right: 21px;
        margin-top: 21px;
    }
    .shopCangKuTable{
        border: 1px solid #EEEEEE;
        border-collapse:collapse;
        width: 100%;
        table-layout:fixed;
        .shopCangKuThTr{
            height: 40px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            background: #FFFFFF;
            th{
                text-align: center;
            }
            th:nth-child(1){
                // padding-left: 29px;
            }

        }
        .shopCangKuTdTr{
            height: 40px;
            border-top: 1px solid #EEEEEE;
            background: #FFFFFF;
            text-align: center;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            td:nth-child(2){
                // padding-left: 29px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            td:nth-child(5){
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #D94511;
            }
            td:nth-child(9){
                .sjbj{
                    margin-right: 10px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #1A69E0;
                }
                .xiangqing{
                    // margin-left: 10px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                }
            }
        }
        .shopCangKuTdTr:nth-child(odd){
            background: #F9F9F9;
        }
    }


}
.cangkuModal{
    .ant-modal-content{
        width: 800px;
        height: 850px;
        background: #FFFFFF;
    }
    .jiben{
        // padding-left: 20px;
        .shopTitle{
            margin-bottom: 10px;
            .shopTitleSpan{
                display: inline-block;
                // min-width: 70px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
            }
            .shopTitleInput{
                width: 600px;
                height: 40px;
                padding-left:20px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #C7C7C7;
                background: #F9F9F9;
                border: 1px solid #EEEEEE;
            }

        }
        .shopFuTitle{
            margin-bottom: 10px;
            .shopFuTitleSpan{
                display: inline-block;
                // min-width: 70px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
            }
            .shopFuTitleInput{
                width: 600px;
                height: 40px;
                padding-left:20px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #C7C7C7;
                background: #F9F9F9;
                border: 1px solid #EEEEEE;
            }

        }
        .shoppz{
            margin-bottom: 10px;
            .shoppzSpan{
                display: inline-block;
                // min-width: 70px;
            }
            .shoppzSpace {
                
                .ant-space-item:nth-child(1){
                    // margin-left: -8px;
                    .xialashoppz{
                        width: 600px;
                        height: 40px;
                        background: #F9F9F9;
                        border: 1px solid #EEEEEE;
                        :nth-child(1){
                            float: left;
                        }
                        :nth-child(2){
                            float: right;
                            line-height: 40px;
                        }
                    }
                }
            }
        }
        .qipiliang{
            overflow: hidden;
            margin-bottom: 10px;
            .qipiliangSpan{
                float: left;
                display: inline-block;
                // min-width: 70px;
            }
            .qipiliangdiv{
                float: left;
                padding-left: 16px;
                padding-right: 10px;
                width: 600px;
                height: 100px;
                background: #F9F9F9;
                border: 1px solid #EEEEEE;
                .duoshaodai{
                    float: left;
                    width: 80px;
                    text-align: center;
                    margin-top: 19px;
                    margin-right: 55px;
                    .duoshaodaispan{
                        width: 80px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #000000;
                    }
                    .duoshaodaiinput{
                        width: 80px;
                        height: 30px;
                        text-align: center;
                        background: #FFFFFF;
                        border: 1px solid #EEEEEE;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #000000;
                    }
                }
                .yunfei{
                    width: 190px;
                    float: left;
                    margin-top: 19px;
                    // line-height: 100px;
                    .yunfeispan{
                        display: inline-block;
                        width: 80px;
                        text-align: center;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #000000;
                    }
                    .yunfeiyf{
                        .yunfeiSpace{
                            float: left;
                            .xialayf{
                                width: 120px;
                                height: 30px;
                                background: #FFFFFF;
                                border: 1px solid #EEEEEE;
                                span:nth-child(1){
                                    float: left;
                                }
                                span:nth-child(2){
                                    float: right;
                                }
                            }
                        }
                        .yunfeidel{
                            float: left;
                            height: 30px;
                        }
                    }
                }
               .qipiadd{
                   float: right;
                   line-height: 100px;
               }
            }
        }
        .zhuangtai{
            overflow: hidden;
            .zhuangtaiSpan{
                float: left;
                display: inline-block;
                // min-width: 70px;
            }
            .zhuangtaixuanze{
                float: left;
                width: 200px;
                height: 40px;
                padding-left:20px;
            }
        }
    }
    .shopjieshao{
        .jieshao-lunbo{
            height: 80px;
            margin-bottom: 10px;
            // overflow: hidden;
            .lunspan{
                float: left;
            }
            .chuanlun{
                float: left;
                height: 80px;
                .ant-upload-list-picture-card-container{
                    width: 80px;
                    height: 80px;
                    .ant-upload-list-item{
                        padding:0;
                        border:none;
                    }
                    
                }
                .ant-upload{
                    width: 80px;
                    height: 80px;
                    margin: 0;
                }
            }
        }
        .jieshao-jieshao{
            overflow: hidden;
            .jieshaospan{
                float: left;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
            }
            .jieshaoinput{
                float: left;
                width: 600px;
                height: 100px;
                padding: 13px 21px ;
                background: #F9F9F9;
                border: 1px solid #EEEEEE;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 20px;
                resize: none;
            }
        }
        .jieshao-suyuan{
            margin-top: 10px;
            overflow: hidden;
            .suyuanspan{
                float: left;
                height: 50px;
                line-height: 50px;
            }
            .suyuanbtn{
                float: left;
                background: #F9F9F9;
                .ant-btn{
                    width: 600px;
                    height: 50px;
                    background: #F9F9F9;
                    border: 1px solid #EEEEEE;
                }
            }
        }
        .jieshao-shouhou{
            overflow: hidden;
            margin-top: 10px;

            .shouhouspan{
                float: left;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
            }
            .shouhouinput{
                float: left;
                width: 600px;
                height: 100px;
                padding: 13px 21px ;
                background: #F9F9F9;
                border: 1px solid #EEEEEE;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 20px;
                resize: none;
            }
        }
        .jieshao-weizhi{
            overflow: hidden;
            margin-top: 10px;
            .weizhispan{
                float: left;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
            }
            .xialaweizhi{
                width: 600px;
                height: 40px;
                background: #F9F9F9;
                border: 1px solid #EEEEEE;
                :nth-child(1){
                    float: left;
                }
                :nth-child(2){
                    float: right;
                    line-height: 40px;
                }
            }
        }
    }
    .ant-modal-footer{
        text-align: center;
        border-top: none;
        margin-top: 0;
    }
    .modalFooter{
        .ant-btn:nth-child(2){
            margin: 0 30px;
        }
    }
}