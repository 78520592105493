.shopGuiGe{
    width: 1680px;
    height: 916px;
    margin: auto;
    background: #ffff;
     .shopGuiGeTop{
        width: 1680px;
        height: 60px;
        padding-left: 30px;
        padding-right: 20px;
        background: #FFFFFF;
        border-bottom: 1px solid #EEEEEE;
        .shopGuiGeTopLf{
            width: 300px;
            height: 60px;
            line-height: 60px;
            float: left;
            .unitName{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #808080;
            }
            .unitSearch{
                width: 200px;
                height: 35px;
                padding-left: 10px;
                margin-left: 10px;
                background: #FFFFFF;
                border: 1px solid #EEEEEE;
            }
        }
        .shopGuiGeTopRg{
            float: right;
            line-height: 60px;
            .ant-btn{
                width: 90px;
                height: 30px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
            }
          

        }
    }

    .shopGuiGeBody{
        padding: 20px;
        .shopGuiGeTable{
            width: 100%;
            .shopGuiGeThead{
                width: 100%;
                height: 40px;
                background: #FFFFFF;
                .shopGuiGeTr{
                    height: 40px;
                    line-height: 40px;
                    display: flex;
                    justify-content: space-between;
                    border: 1px solid #EEE;
                    th{
                        width: 100px;
                        text-align: center;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #000000;
                    }
                    th:nth-child(2){
                        width: 200px;    
                    }
                    th:nth-child(3){
                        width: 200px;    
                    }
                    th:nth-child(4){
                        width: 200px;    
                    }
                }
            }
            .shopGuiGeTbody{
                .GuiGeTbodyTr{
                    width: 1640px;
                    height: 50px;
                    line-height: 50px;
                    display: flex;
                    justify-content: space-between;
                    border: 1px solid #EEE ;
                    border-top: none;
                    td{
                        text-align: center;
                        
                    }
                    td:nth-child(1){
                        // min-width: 190px;
                        width: 100px;    
                    }
                    td:nth-child(2){
                        // min-width: 190px;
                        width: 200px;    
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    td:nth-child(3){
                        // min-width: 190px;
                        width: 200px;
                        text-align: center;
                    }
                    td:nth-child(4){
                        width: 200px;
                        overflow: hidden;
                    }
                    td:nth-child(5)>div{
                        width: 80px;
                        margin-right: 10px;
                        display: flex;
                        justify-content: space-between;
                    }
                    :nth-child(5)>div span:nth-child(1){
                        font-size: 12px;
                        cursor: pointer;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #1A69E0;
                    }
                    :nth-child(5)>div span:nth-child(2){
                        font-size: 12px;
                        cursor: pointer;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #000000;
                    }
                }
                .GuiGeTbodyTr:nth-child(odd){
                    background: #F9F9F9;
                }
            }
        }
    }
}
.ModalTop{
    margin-top: 20px;
    .guigeSize{
        margin-right: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000;
    }
    .guigejieshao{
        width: 600px;
        height: 40px;
        padding-left: 20px;
        background: #F9F9F9;
        border: 1px solid #EEEEEE;
    }
   
}
.specificationK{
    margin-top: 20px;
    margin-left: 82px;
}
.shopGuiGeModal{
    .ant-modal-content{
        width: 800px;
        height: 500px;
        background: #FFFFFF;
        .ant-modal-body{
            padding-top:0 ;
        }
        .ant-modal-footer{
            text-align: center;
            border-top: none;

            .ant-btn:nth-child(1){
                width: 100px;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #1A69E0;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #1A69E0;
            }
            .ant-btn:nth-child(2){
                width: 100px;
                height: 40px;
                background: #1A69E0;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
    .ant-modal-footer {
        margin-top:0;
    }
}