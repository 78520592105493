.tool-title {
  display: flex;
  height: 60px;
  box-sizing: border-box;
  border-bottom: 1px solid #EEEEEE;
  text-align: center;
  line-height: 60px;
  padding-left: 20px;
}
.tool-title h1 {
  margin: 0;
  font-size: 18px;
  font-family: Microsoft YaHei;
}
.tool-title p {
  margin: 0;
}
.tool-title .reply-setting-contain {
  font-size: 20px;
  padding: 20px;
}
.tool-title .reply-setting-contain .h110 {
  height: 110px;
}
.tool-title .reply-setting-contain .h110 .reply-setting-left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.tool-title .reply-setting-contain .h110 .reply-setting-left .fs16c90 {
  font-size: 16px;
  color: #909090;
}
.tool-title .reply-setting-contain .reply-setting {
  background-color: #eee;
  padding: 0 30px;
  margin-bottom: 10px;
  width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
