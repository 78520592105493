.trade-statistical {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.trade-statistical .m0-5 {
  margin: 0 10px;
}
.trade-statistical .trade-item {
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 545px;
}
.trade-statistical .trade-item .color-blue {
  background-color: #1A69E0;
}
.trade-statistical .trade-item .color-orange {
  background-color: #FF8812;
}
.trade-statistical .trade-item .color-red {
  background-color: #D94511;
}
.trade-statistical .trade-item .left-color {
  width: 12px;
}
.trade-statistical .trade-item .item-show {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
  background-color: white;
  padding: 36px 0;
}
.trade-statistical .trade-item .item-show .item-show-img {
  width: 55px;
  height: 55px;
}
.trade-statistical .trade-item .item-show .item-num {
  font-size: 24px;
  font-weight: bold;
}
.trade-statistical .trade-item .item-show .item-title-note {
  font-size: 14px;
  font-weight: 400;
  width: 178px;
  text-align: center;
}
p {
  margin: 0;
}
.title-withdraw-info {
  font-size: 16px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #010101;
  line-height: 20px;
  margin-bottom: 20px;
}
.title-withdraw-info .colors {
  color: #B92115;
}
.title-withdraw-info .colorr {
  color: #B92115;
  font-size: 12px;
  margin-left: 40px;
}
.mb40 {
  margin-bottom: 40px;
}
.operation {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  font-size: 16px;
}
.operation span.title-left {
  width: 80px;
  text-align: right;
}
.operation .num {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  font-size: 16px;
  padding: 0 0 0 20px;
  margin: 0 10px;
  align-items: center;
  width: 400px;
  height: 40px;
  background-color: #f9f9f9;
  border: 1px solid #eee;
}
.operation .withdraw-all {
  height: 40px;
  width: 84px;
  margin: 0 10px 0 0;
}
.operation .change {
  margin-left: 15px;
  color: #1A69E0;
  text-decoration: underline;
}
.operation .money-input {
  width: 246px;
  margin-right: 0;
}
.operation .submit {
  margin-top: 100px;
  padding: 0;
  border: none;
  background-color: rgba(255, 255, 255);
}
.operation .submit .withdraw-btn {
  height: 100%;
  width: 200px;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}
