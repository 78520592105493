.detail-header{
    display: flex;

}
.fs14px{
    font-size: 14px;
    margin-right: 15px;
}
.modal-content{
    /* padding: 20px 0; */
}
.detail-area{
    width: 100%;
    height: 350px;
}
.detail-area::-webkit-scrollbar{
    display: none;
    
}
.detail-area{
    overflow-y: scroll;
    width: 100%;
    background-color: #F9F9F9;
    padding: 20px;
    color: #666;
}
.w70px{
    width: 70px !important;
    height: 20px;
}
.flex{
    display: flex;
}