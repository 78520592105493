.main-show .full {
  width: 100%;
  min-height: 775px;
}
.main-show .full .ant-tabs-extra-content {
  margin-right: 30px;
}
.main-show .full .ant-tabs-nav-wrap {
  margin-left: 30px;
  font-size: 18px;
  height: 60px;
}
.main-show .full .ant-tabs-tab-btn {
  font-size: 18px;
}
.main-show .full .button-add {
  width: 90px;
  height: 30px;
}
.main-show .full .reply-auto {
  display: flex;
  padding: 0 20px;
}
.main-show .full .reply-auto .flexclo .reply-auto-title {
  font-size: 24px;
  margin: 0;
  padding: 0 0 27px;
}
.main-show .full .reply-auto .reply-auto-left .reply-auto-phrase-list {
  border-top: 1px solid #eee;
}
.main-show .full .reply-auto .reply-auto-left .reply-auto-phrase-list li {
  border: solid 1px #eee;
  border-top: none ;
  width: 600px;
  height: 50px;
  font-size: 14px;
  text-align: center;
  line-height: 50px;
}
.main-show .full .reply-auto .reply-auto-left .reply-phrase-opration {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 114px;
}
.main-show .full .reply-auto .reply-auto-left .reply-phrase-opration .w90h40 {
  width: 90px;
  height: 40px;
  font-size: 14px;
}
.main-show .full .reply-auto .reply-auto-left .reply-phrase-opration .b1c {
  border: solid 1px #1A69E0;
  color: #1A69E0;
  box-sizing: border-box;
}
.main-show .full .reply-auto .reply-auto-right {
  margin-left: 200px;
}
.main-show .full .reply-auto .reply-auto-right .auto-replay-setting-item-title {
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  margin: 0;
  padding: 0;
}
.main-show .full .reply-auto .reply-auto-right .select-box {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-show .full .reply-setting-contain {
  font-size: 20px;
  padding: 20px;
}
.main-show .full .reply-setting-contain .h110 {
  height: 110px;
}
.main-show .full .reply-setting-contain .h110 .reply-setting-left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.main-show .full .reply-setting-contain .h110 .reply-setting-left .fs16c90 {
  font-size: 16px;
  color: #909090;
}
.main-show .full .reply-setting-contain .h110 .reply-setting-left .fs16c90 p {
  margin: 0;
}
.main-show .full .reply-setting-contain .h70 {
  height: 70px;
}
.main-show .full .reply-setting-contain .reply-setting {
  background-color: #eee;
  padding: 0 30px;
  margin-bottom: 10px;
  width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
