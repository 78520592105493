.ant-menu {
  background-color: #1F2128 !important;
}
.ant-menu .page-home {
  height: 60px !important ;
  display: flex !important;
  align-items: center !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.ant-menu-submenu-title {
  display: flex !important;
  align-items: center !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.nav-icon {
  margin-right: 10px;
}
