.sale-item {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.sale-item .sale-item-title {
  margin-right: 10px;
  line-height: 35px;
}
.sale-item .sale-item-value {
  width: 100px;
  height: 35px;
  background-color: #f9f9f9;
  border: 1px solid #eee;
  box-sizing: border-box;
  display: inline-block;
  line-height: 35px;
}
