.main-page .cards {
  width: 100%;
  display: flex;
  justify-content: center;
}
.main-page .cards .card1 {
  background: url(img/blue.png) no-repeat;
}
.main-page .cards .card2 {
  background: url(img/red.png) no-repeat;
}
.main-page .cards .card3 {
  background: url(img/lightblue.png) no-repeat;
}
.main-page .cards .card4 {
  background: url(img/orange.png) no-repeat;
}
.main-page .cards .cards-item {
  color: #fff;
  margin: 0 -5px;
  max-width: 434px ;
  height: 200px;
  background-size: 100% 100%;
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  align-items: center;
}
.main-page .cards .cards-item .card-name {
  font-size: 18px;
}
.main-page .cards .cards-item .card-name img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.main-page .cards .cards-item .number {
  font-size: 28px;
}
.main-page .cards-area {
  display: flex;
}
.main-page .cards-left {
  flex-grow: 1;
}
.main-page .cards-todo-data {
  background-color: #fff;
  padding: 0 20px;
  margin: 0 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 15px 0px rgba(144, 144, 144, 0.1);
}
.main-page .cards-title {
  height: 54px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
}
.main-page .cards-title-text {
  font-size: 14px;
  border-left: 6px solid #1A69E0;
  height: 20px;
  padding-left: 10px;
}
.main-page .cards-title-order-all {
  color: #808080;
  font-size: 12px;
  line-height: 20px;
}
.main-page .todo-items {
  height: 195px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-page .todo-item {
  cursor: pointer;
  width: 110px;
  height: 110px;
  background-color: #f8fafe;
  box-sizing: border-box;
  padding: 22px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.main-page img {
  width: 22px;
  height: 22px;
}
.main-page .todo-item-name {
  font-size: 14px;
  height: 14px;
}
.main-page .todo-item-number {
  color: #1A69E0;
  height: 12px;
  font-size: 14px;
}
.main-page .cards-center {
  flex-grow: 1;
  margin: 0;
}
.main-page .cards-center .todo-items {
  justify-content: space-around;
}
.main-page .cards-center .todo-item-center {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 110px;
}
.main-page .cards-center .todo-item-center img {
  width: 40px;
  height: 40px;
}
.main-page .cards-center .todo-item-center span {
  font-weight: 600;
  height: 40px;
  margin-top: 10px;
}
.main-page .cards-right {
  flex-grow: 1;
}
.main-page .cards-right .vertical-line {
  height: 36px;
  border-right: 1px solid #eee;
}
.main-page .cards-right .todo-item-right {
  cursor: pointer;
  max-width: 110px;
  height: 110px;
  box-sizing: border-box;
  padding: 22px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}
.main-page .cards-right .todo-item-right .name {
  font-size: 12px;
  color: #808080;
}
.main-page .cards-right .todo-item-right .number {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  height: 36px;
}
.main-page .charts {
  margin: 10px;
  display: flex;
  flex-direction: column;
}
.main-page .charts .todo-chart {
  width: 100%;
}
.main-page .charts .date-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pointer {
  cursor: pointer;
}
.color000 {
  color: #000;
}
