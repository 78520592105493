.table-foot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}
.table-foot .foot-left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-foot .foot-left .table-import {
  font-size: 12px;
  width: 78px;
}
.table-foot .foot-left .table-export {
  font-size: 12px;
  width: 50px;
  height: 30px;
  margin-left: 31px;
  padding: 0;
}
.table-foot .foot-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
.table-foot .foot-right .table-num {
  margin-right: 50px;
}
.table-foot .foot-right input {
  border: 1px solid #eee;
  outline: none;
  width: 50px;
  height: 30px;
  text-align: center;
  font-size: 14px;
  color: #000;
  margin: 0 10px;
}
.table-foot .foot-right .jump {
  width: 50px;
  margin-left: 20px;
  height: 30px;
  padding: 0;
  text-align: center;
}
.fdcol {
  flex-direction: column;
}
