.login {
  min-width: 1180px;
  width: 100%;
  height: 100%;
  min-height: 700px;
  background: url(bagkground.png) no-repeat;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  align-items: center;
}
